import { createStore } from 'zustand'
import type { experimentStateType } from '../../experiment/stores/ExperimentStore'
import { communityFilterSlice } from '../../community/stores/communityFilterSlice'
import type { GlobalStoreActionTypes } from './GlobalStoreActions'
import { globalStoreActions } from './GlobalStoreActions'
import { businessPostFilterSlice } from '../../businessPost/stores/businessPostFilterSlice'
import { placeFilterSlice } from '../../place/stores/placeFilterSlice'

// TODO: Slice 추가 시 여기에 타입 추가
type SliceTypes = ReturnType<typeof communityFilterSlice> &
  ReturnType<typeof businessPostFilterSlice> &
  ReturnType<typeof placeFilterSlice>

export type GlobalState = {
  experimentState: experimentStateType
  actions: GlobalStoreActionTypes
} & SliceTypes

export type GlobalStore = ReturnType<typeof createGlobalStore>

export interface GlobalStoreProps {
  initGlobalState: Partial<GlobalState>
}

export const createGlobalStore = ({ initGlobalState }: GlobalStoreProps) => {
  const DEFAULT_PROPS = {
    experimentState: {
      experimentSegments: [],
      headerSegmentKey: '',
      headerSegmentValue: '',
    },
  }

  return createStore<GlobalState>()((...args) => ({
    ...DEFAULT_PROPS,
    ...initGlobalState,
    // TODO: Slices of state
    ...communityFilterSlice(...args),
    ...businessPostFilterSlice(...args),
    ...placeFilterSlice(...args),
    // Actions
    actions: globalStoreActions(...args),
  }))
}
